import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <a className="footer__link" href="mailto:bogumilmorawski@gmail.com">bogumilmorawski@gmail.com</a>
    </footer>
  );
}

export default Footer;
