import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './styles/main.scss';

import Header from './components/Header';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Skills from './pages/Skills';
import Nav from './components/Nav';
import Footer from './components/Footer';

const App = () => {
  const [ navOpen, setNavOpen ] = useState(null);
  const [loading, setLoading] = useState(true);
  const [firstEnter, setFirstEnter] = useState(true);
  


  useEffect(()=>{
    AOS.init();
    setTimeout(() => {
      setLoading(false)
    }, 3000);
  }, [])

  useEffect(()=>{
    if(!loading) {
      setTimeout(() => {
        setFirstEnter(false);
      }, 4000);
    }
  }, [loading])
  
  useEffect(()=> {
    if(navOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [navOpen]);
  
  return (
    <>
      <Header navOpen={navOpen} setNavOpen={setNavOpen} />
      <Nav navOpen={navOpen} setNavOpen={setNavOpen} />
      <Switch>
        <Route exact path="/" render={props => <Home {...props} loading={loading} firstEnter={firstEnter} />} />
        <Route path="/contact" component={Contact} />
        <Route path="/skills" component={Skills} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
