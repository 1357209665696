import github from '../img/links_logos/github.svg';
import gmail from '../img/links_logos/gmail.svg';
import twitter from '../img/links_logos/twitter.svg';


export default [
  {
    id: 'github',
    image: github,
    url: 'https://github.com/kryptondv',
  },
  {
    id: 'gmail',
    image: gmail,
    url: 'mailto:bogumilmorawski@gmail.com',
  },
  {
    id: 'twitter',
    image: twitter,
    url: 'https://twitter.com/krypton_dv',
  },
];
