import React, {useEffect, useRef} from 'react';
import socialLinks from '../assets/data/socialLinks';
import gsap from 'gsap';

const SocialLinks = () => {
  const container = useRef(null);

  useEffect(()=>{
    const linksEl = container.current.children;
    gsap.from(linksEl, {duration: 1, opacity: 0, x: 10, stagger: -.2})
  }, [])
  return (
    <div ref={container} className="social-links">
      {socialLinks.map(({id, url, image})=><a key={id} href={url}><img src={image} alt="link"/></a>)}
    </div>
  );
}

export default SocialLinks;
