import html from '../img/skills/html5.svg';
import css from '../img/skills/css3.svg';
import js from '../img/skills//javascript.svg';
import git from '../img/skills/git.svg';
import react from '../img/skills/react.svg';
import redux from '../img/skills/redux.svg';
import saas from '../img/skills/sass.svg';
import gsap from '../img/skills/greensock.svg';

export default [
  {
    id: '01',
    name: 'html',
    image: html,
   
  },
  {
    id: '02',
    name: 'css',
    image: css,
   
  },
  {
    id: '03',
    name: 'javascript',
    image: js,
   
  },
  {
    id: '04',
    name: 'git',
    image: git,
   
  },
  {
    id: '05',
    name: 'react',
    image: react,
   
  },
  {
    id: '06',
    name: 'redux',
    image: redux,
   
  },
  {
    id: '07',
    name: 'saas',
    image: saas,
   
  },
  {
    id: '08',
    name: 'gsap',
    image: gsap,
   
  },
  
];
