import React from 'react';
import { ReactComponent as GithubLogo } from '../assets/img/github.svg';

const ProjectCard = ({ project }) => {
    const { name, description, image, url, githubUrl } = project;
    return (
        <div
            className="project-card"
            data-aos="fade-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="1000"
            data-aos-offset="-20"
        >
            <div className="project-card__flex">
                <div className="project-card__left">
                    <a href={url} aria-label={`Go to live demo of the ${name} project`}>
                        <video
                            className="project-card__img"
                            loop
                            playsInline
                            autoPlay
                            muted
                            src={image}
                            preload="auto"
                        />
                    </a>
                </div>
                <div className="project-card__right">
                    <h3 className="project-card__title">{name}</h3>
                    <p className="project-card__description">{description}</p>
                </div>
            </div>
            {/* <h4 className="project-card__links-title">links:</h4> */}
            <div className="project-card__links">
                <a
                    className="project-card__link"
                    href={githubUrl}
                    aria-label={`Go to github repo of the ${name} project`}
                >
                    <GithubLogo />
                </a>
                <a className="project-card__link" href={url} aria-label={`Go to live demo of the ${name} project`}>
                    Live
                </a>
            </div>
        </div>
    );
};

export default ProjectCard;
