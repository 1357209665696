import React, { useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import gsap from 'gsap';

const Nav = ({ navOpen, setNavOpen, history }) => {

  const wrapper = useRef(false);

  useEffect(()=>{
    history.listen(()=>{
    setNavOpen(false)})
  })

  useEffect(()=> {
    const [nav] = wrapper.current.children;
    const navMainBg = nav.querySelector('.nav__main-background');
    const navSecondBg = nav.querySelector('.nav__secondary-background');
    const links = nav.querySelectorAll('.nav__link')
    const listItems = nav.querySelectorAll('.nav__list-item')
    if(navOpen) {

      gsap.to(nav, {
        duration: 0,
        css: { display: 'block' },
      });
      gsap.to([navMainBg, navSecondBg], {
        duration: 0,
        height: '100%',
      });

      gsap.from([navSecondBg, navMainBg], {
        duration: .8,
        height: 0,
        transformOrigin: 'right top',
        skewY: 10,
        ease: 'power3.inOut',
        stagger: 0.1
      });

      gsap.from(links, {
        duration: 0.2,
        opacity: 0,
        delay: 0.2,
        ease: 'power3.inOut',
        stagger: .2
      });

      gsap.from(listItems, {
        duration: .8,
        y: 400,
        ease: 'power3.inOut',
        stagger: .2
      });

    } else if (navOpen === false){
      gsap.to([navMainBg, navSecondBg], {
        duration: 0.8,
        height: 0,
        ease: 'power3.inOut',
        stagger: {
          amount: 0.07,
        },
      });
      gsap.to(nav, {
        duration: 1,
        css: { display: 'none' },
      });
    }
  }, [navOpen])

  return (
    <div ref={wrapper} className="wrapper">
      <nav className="nav">
        <div className="nav__main-background">
          <div className="container">
            <ul className="nav__list">
              <li className="nav__list-item">
                <Link className="nav__link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav__list-item">
                <Link className="nav__link" to="/skills">
                  Skills
                </Link>
              </li>
              <li className="nav__list-item">
                <Link className="nav__link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="nav__secondary-background"></div>
      </nav>
    </div>
  );
};

export default withRouter(Nav);
