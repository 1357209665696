import React, {useState} from 'react';

const Hamburger = ({ navOpen, setNavOpen }) => {
  const [disabled, setDisabled] = useState(false);

  const onButtonClick = () => {
    setNavOpen(prevState => !prevState);
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false)
    }, 1000);
  }
  
  return (
    <button
      className={`hamburger ${navOpen ? 'hamburger--clicked' : null }`}
      onClick={onButtonClick}
      disabled={disabled}
      aria-label="Toggle navigation menu"
    >
      <div className="hamburger__bars">
        <div className="hamburger__bar hamburger__bar--top"></div>
        <div className="hamburger__bar hamburger__bar--bottom"></div>
      </div>
    </button>
  );
};

export default Hamburger;
