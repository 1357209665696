import React from 'react';
import { Link } from 'react-router-dom';

import Hamburger from './Hamburger';

const Header = ({ ...props }) => {
  return (
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <Link className="header__logo" to="/">
            /bogumilmorawski
          </Link>
          <Hamburger {...props}/>
        </div>
      </div>
    </header>
  );
};

export default Header;
