import React from 'react';
import { Link } from 'react-router-dom';
import SocialLinks from './SocialLinks';

const Hero = ({ firstEnter }) => {
  return (
    <section className="hero">
      <div className="hero__title-container">
        <h1
          className={`hero__title ${
            firstEnter ? 'hero__title--first-enter' : null
          }`}
        >
          Hello! I'm Bogumił.
        </h1>
      </div>
      <p
        className={`hero__subtitle ${
          firstEnter ? 'hero__subtitle--first-enter' : null
        }`}
      >
        <span>
          I’m a Front-End Developer based in Katowice, PL and interested in
          modern web technologies.
        </span>
        <span>My main technology is React JS. </span>
        <span>
          Feel free to contact me through the{' '}
          <Link className="hero__link" to="/contact">
            contact form
          </Link>{' '}
          or{' '}
          <a className="hero__link" href="mailto:bogumilmorawski@gmail.com">
            email
          </a>
          .
        </span>
      </p>
      <SocialLinks />
    </section>
  );
};

export default Hero;
