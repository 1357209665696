import React, {useRef, useEffect} from 'react';
import skills from '../assets/data/skills';
import gsap from 'gsap';

const Skills = () => {
  const skillsContainer = useRef(null);

  useEffect(()=> {
    const skillsElements = skillsContainer.current.children;
    gsap.from(skillsElements, {duration: 1.5, opacity: 0, y:10, stagger: .1}, .2)
  }, [])
  return (
    <section className="skills">
      <h2 className="skills__title">Skills</h2>
      <div ref={skillsContainer} className="skills__container">
        {skills.map(skill => (
          <div className="skill" key={skill.id}>
            <img
              className="skill__image"
              src={skill.image}
              alt={`${skill.name} logo`}
            />
            <span className="skill__name">{skill.name}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
