import React, {useEffect} from 'react';
import Hero from '../components/Hero';
import Welcome from '../components/Welcome';
import Work from '../components/Work';

const Home = ({loading, firstEnter}) => {
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="container">
      {loading && <Welcome />}
      <Hero firstEnter={firstEnter} />
      <Work />
    </div>
  );
}

export default Home;
