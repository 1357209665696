import silesiaTowers from '../video/silesia-towers.mp4';
import toki from '../video/toki.mp4';
import artBooks from '../video/art-books.mp4';

const projects = [
  {
    id: '01',
    name: 'Silesia Towers',
    description:
      'Fictional real estate service with apartment finder and preview.',
    image: silesiaTowers,
    technologies: ['React', 'Saas'],
    url: 'https://silesia-towers.netlify.app/',
    githubUrl: 'https://github.com/kryptondv/silesia-towers',
  },
  {
    id: '02',
    name: 'Messaging App',
    description: 'React & Firebase chat application.',
    image: toki,
    technologies: ['React', 'Saas', 'Firebase'],
    url: 'https://tokitoki.netlify.app/',
    githubUrl: 'https://github.com/kryptondv/toki-chat-app',
  },
  {
    id: '03',
    name: 'Art Books Store',
    description:
      'React e-commerce service for a fictional artbooks store. Product data is provided by Contentful headless CMS.',
    image: artBooks,
    technologies: ['React', 'Saas', 'Contentful'],
    url: 'https://artbooks.netlify.app/',
    githubUrl: 'https://github.com/kryptondv/react-art-books',
  },
];

projects.reverse();
export default projects;