import React from 'react';
import projects from '../assets/data/projects';

import ProjectCard from './ProjectCard';

const Work = () => {
  return (
    <section className="work">
      <h2 className="work__title">Works</h2>
      {projects.map(project => <ProjectCard key={project.id} project={project}/>)}
    </section>
  );
}

export default Work;
