import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

const Welcome = () => {
  const welcome = useRef(null)

  useEffect(() => {
    const elements = welcome.current
    const title = elements.querySelector('.welcome__title')
    const bgPrimary = elements.querySelector('.welcome__primary');
    const bgSecondary = elements.querySelector('.welcome__secondary');
    gsap.from(bgPrimary, {duration: .6, backgroundColor: '#000'})
    gsap.fromTo(title, {y: -50},{
      duration: 1.5,
      y: '+=50',
      opacity: 1,
      ease: 'bounce',
    });
    gsap.to([bgPrimary, bgSecondary], { duration: 1.4, ease: 'power3.inOut' , width: 0, skewX: -5, transformOrigin:'top left',stagger: .1}, 1.4);
  }, []);

  return (
    <div ref={welcome} className="welcome">
      <div className="welcome__primary">
        <div className="welcome__title-container">
          <h2 className="welcome__title">bogumilmorawski.com</h2>
          </div>
      </div>
      <div className="welcome__secondary"></div>
    </div>
  );
}

export default Welcome;
